import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SacouponsSubscriptionService {
    // live
    baseUrl = 'https://tecsa-sacoupons-api.azurewebsites.net/api/subscriptions/';

    // staging
    //baseUrl = 'https://livelife-sacoupons-api-staging.azurewebsites.net/api/subscriptions/';

    // local
    // baseUrl = 'https://localhost:5001/api/subscriptions/';

    selectedCoupons: string[];

    constructor(
      private http: HttpClient) { }

    // gets the retailer list
    getSubscription(userId) {
      return this.http.get(`${this.baseUrl}getsubscription/${userId}`);
    };

    // gets the retailer list
    createSubscription(userId) {
      const id: number = userId;
      const createSubscription = {
        userId: id,
        msisdn: ''
      };

      console.log(createSubscription);
      return this.http.post(`${this.baseUrl}`, createSubscription);
    };
}
